import React from "react";
import logo2 from "../Image/logo_w.png";
import opensea from "../Image/opensea.png";
import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { AiOutlineInstagram, AiOutlineMedium } from "react-icons/ai";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer p-4">
      <div className="container px-5">
        <div className="mx-auto d-flex flex-column flex-lg-row justify-content-center  align-items-center">
          {/* <div className="footer-text mb-2">Fast Food Apes</div> */}
          <div className="img mb-2">
            {/* <img
              // src="https://cryptomories.iwwon.com/images/tetesolo.png"
              src={logo2}
              alt=""
              className="img-fluid"
              width="90px"
              className="img-fluid"
            /> */}
          </div>
          <div className="icon-part mt-2">
            <div className=" mx-3">
              {/* <a
                href="https://opensea.io/collection/FastFoodApes"
                target="_blank"
              >
                <img
                  src={opensea}
                  alt=""
                  className="social-icon img-fluid"
                  width="25px"
                />
              </a> */}
            </div>

            <div className="twitter mx-3">
              <a href="https://twitter.com/TheFastFoodApes/" target="_blank">
                <BsTwitter className="social-icon" />
              </a>
            </div>
            {/* <div className="discord mx-3">
              <a href="https://discord.com/invite/FastFoodApes" target="_blank">
                <FaDiscord className="social-icon" />
              </a>
            </div> */}
            {/* <div className="instagram mx-3">
              <a
                href="https://www.instagram.com/FastFoodApesnft"
                target="_blank"
              >
                <AiOutlineInstagram className="social-icon" />
              </a>
            </div> */}
            {/* <div className="icytools mx-3">
              <a href="https://icy.tools/calendar" target="_blank">
                {" "}
                <img
                  src="https://cdn.icy.tools/images/icy-tools-dark.svg"
                  alt="as seen on icy.tools"
                  width="80"
                />
              </a>
            </div> */}
            {/* <div className="medium mx-3">
              <a href="https://medium.com/@FastFoodApes" target="_blank">
                <AiOutlineMedium className="social-icon" />
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
