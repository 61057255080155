import React, { useEffect, useState } from "react";
import { purchaseTokensPlusPurchaseTokensFree } from "../../eth-setup/apis";
import "./Mint.css";
const Mint = () => {
  const maxVal = 20;
  const [qty, setQty] = useState(1);

  // useEffect(() => {
  //   if (value > 3) setValue(3);
  //   else if (value < 1) setValue(1);
  // }, [value]);

  return (
    <div className="mint-main">
      <div className="container" id="mint">
        <div className="right col-10 mx-auto p-3">
          {/* <div className="title padding">MINTING</div> */}
          {/* <div className="right-title mb-3 text-center">Mint Here</div> */}
          <div className="description text-center mt-4">
            {/* 8888 Apes - 2000 Free Mint (max 2 x wallet) <br />
            0.0069 ETH the others (max 10 x wallet). <br /> */}
            {/* Instant Reveal. <br /> */}
            Total supply: 8,888. <br />
            Free mint: 2,000 (max 5x wallet). <br />
            Public mint: 6888 at 0.0069 eth (max 20x wallet). <br />
            {/* Reserved 4 the team: 444. */}
            {/* $10,000 raffle: just 4 one lucky ape! */}
            {/* 2000 reserved for FastFoodApes <br />
            2000 free to mint for everyone <br /> (1 free + gas mint per wallet){" "}
            <br /> <br />
            remaining amount 0.01 ETH each <br />
            (max 5 per wallet) <br /> */}
          </div>
          <div className="mint text-center mt-4 mb-5">
            {/* <div className="mint-title mb-2">{total}/5000</div> */}
            {/* <div className="mint-input d-flex justify-content-center align-items-center">
              <div
                className="btn-minus mx-3"
                onClick={() => setValue(value - 1)}
              >
                <i class="fas fa-minus"></i>
              </div>
              <div className="input mx-3">{value}</div>

              <div
                className="btn-plus mx-3"
                onClick={() => setValue(value + 1)}
              >
                <i class="fas fa-plus"></i>
              </div>
            </div> */}
            <div>
              <div className="mint-btn mb-3 mt-4">
                {/* <button
                                type="button"
                                class="btn btn-primary mint-btn-submit"
                                onClick={() =>
                                    purchaseTokensPlusPurchaseTokensFree(() => {}, value)
                                } 
                             > */}

                {/* <input
                  type="number"
                  className="number btn-primary mint-btn-submit text-center"
                  onChange={(e) => {
                    if (Number(e.target.value) > maxVal) setQty(maxVal);
                    else if (e.target.value === "") setQty(e.target.value);
                    else if (Number(e.target.value) < 1) setQty(1);
                    else setQty(e.target.value);
                  }}
                  onClick={(e) => {
                    if (Number(e.target.value) > maxVal) setQty(maxVal);
                    else if (e.target.value === "") setQty(e.target.value);
                    else if (Number(e.target.value) < 1) setQty(1);
                    else setQty(e.target.value);
                  }}
                  value={qty}
                  id=""
                /> */}
                {/* INPUT HERE
                            </button> */}
              </div>
              {/* <div className="mint-btn mb-3 mt-4">
                <button
                  type="submit"
                  class="btn btn-primary mint-btn-submit"
                  onClick={() =>
                    purchaseTokensPlusPurchaseTokensFree(() => {}, qty)
                  }
                >
                  MINT YOUR APES
                </button>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
