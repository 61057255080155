import logo from "./logo.svg";
import "./App.css";
import Navbar from "./Components/Navbar/Navbar";
import Footer from "./Components/Footer/Footer";
import Header from "./Components/Header/Header";
import Mint from "./Components/Mint/Mint";
import Team from "./Components/Team/Team";
import Safety from "./Components/Safety/Safety";
import Concept from "./Components/Concept/Concept";
import Plans from "./Components/Plans/Plans";
import Animation from "./Components/Animation/Animation";
import Main from "./Components/Main/Main";
import Whitelist from "./Components/Whitelist/Whitelist";
import Faq from "./Components/Faq/Faq";

function App() {
  return (
    // <div>
    //   <h1
    //     style={{
    //       textAlign: "center",
    //       marginTop: "100px",
    //       fontFamily: "Comic Neue",
    //       fontWeight: "600",
    //     }}
    //   >
    //     COMING SOON
    //   </h1>{" "}
    // </div>

    <div className="App">
      {/* <Navbar /> */}
      {/* <Main /> */}
      <Header />
      <Mint />
      {/* <Concept /> */}
      {/* <Whitelist /> */}
      {/* <Animation /> */}
      {/* <Plans /> */}
      <div className="dark-background">
        {/* <Team /> */}
        {/* <Safety /> */}
        {/* <Faq /> */}
        <Footer />
      </div>
    </div>
  );
}

export default App;
